:root {
  --project-width: 1200px;
  --font-size-base: 26px;
  --line-height-base: 1.7;
}

body {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  box-sizing: border-box;
}

h1 {
  font-size: calc(var(--font-size-base) * 1.2);
  margin: 0;
}

h2 {
  font-size: calc(var(--font-size-base) * 1.2);
}

a {
  text-decoration: none;
  color: black;
  padding-left: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.header-container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  z-index:20;
}

.header-title,
.language-toggle,
.sidebar-toggle-btn {
  padding: 0.2rem 1rem;
}

.language-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: calc(var(--font-size-base) * 0.8);
}

.sidebar-toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: calc(var(--font-size-base) * 0.8);
  display: none; /* 기본적으로 숨기기 */
}

.main-container {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 400px;
  background-color: #f1efe7;
  border-right: 1px solid black;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  z-index: 10; /* 썸네일보다 위에 표시되도록 설정 */
}

.sidebar.open {
  transform: translateX(0);
}

.intro {
  border-bottom: 1px solid black;
}

.intro p {
  padding: 1rem 1rem 2.4rem 1rem;
}

/* .intro-link-pikant {
  text-decoration: underline 2px solid cornflowerblue;
  text-decoration-skip-ink: none;
  cursor: pointer;
} */


.intro-link-pikant, .project-content-link {
  position: relative;
  text-decoration: none; /* 기본 밑줄 제거 */
  cursor: pointer;
}

.intro-link-pikant::after, .project-content-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; /* 텍스트 아래에 공간을 둡니다 */
  width: 100%;
  height: 2px; /* 밑줄 두께 */
  background-color: cornflowerblue;
}


.intro-superscript {
  font-size: 0.7em;
}

.project-list {
  display: flex;
  flex-direction: column;
}

.project-link {
  padding: 0.8rem;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid black;
}

.project-link:active,
.project-link:focus,
.project-link.active {
  color: white;
  background-color: cornflowerblue;
}


.content {
  flex: 1;
  overflow: auto;
}

.thumbnails {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  background-image:
    linear-gradient(to right, #ddd 1px, transparent 1px),
    linear-gradient(to bottom, #ddd 1px, transparent 1px);
  background-size: 200px 200px;
  background-position: 199px 199px;
  overflow: hidden;
}

.thumbnail {
  width: 300px;
  height: 300px;
  object-fit: cover;
  z-index: 1;
}

.thumbnail:hover {
  z-index: 100;
  transform: scale(1.1);
  transition: transform 0.3s;
}

/* .thumbnail {
  transition: transform 0.3s ease, z-index 0s ease 0.3s;
  z-index: 1;
}

.thumbnail:hover {
  transform: scale(1.5);
  z-index: 100;
  transition: transform 0.3s ease, z-index 0s;
} */


.project-container {
  max-width: 1200px;
  margin: 0 auto;
}

.project-title {
  text-align: center;
  padding: 1rem 0;
}

.project-description {
  padding: 0 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: var(--project-width);
}

.project-text {
  flex: 3;
  padding-right: calc(var(--font-size-base) * 1);
}

/* .project-text .en {
} */

.project-detail {
  margin: 0;
  flex: 1;
  padding-left: 20px;
  border-left: 1px solid #ddd;
  font-size: calc(var(--font-size-base) * 0.7);
  line-height: calc(var(--line-height-base) * 0.8);
}

table td:nth-child(1) {
  /* text-decoration: underline 2px solid #ddd; */
  color: rgb(126, 126, 126);
}

table td:nth-child(2) {
  padding-left: calc(var(--font-size-base) * 0.4);
}

p {
  margin: 0;
}

img {
  margin: 0;
}

.project-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-image-container {
  width: 100%;
  max-width: var(--project-width);
  margin: 10px 0;
  text-align: center;
}

.project-image {
  width: 100%;
  max-width: 1200px;
  height: auto;
  object-fit: cover;
}

.image-caption {
  font-size: 14px;
  color: #555;
}


.project-media {
  margin-top: 20px;
  
}

.lottie-container,
.video-container {
  width: 100%;
  position: relative;
  /* padding-bottom: 56.25%;  */
  /* 16:9 비율 유지 */
  padding-bottom: 66.67%;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;

  background-color: rgb(213, 213, 213);
}

.lottie-container iframe,
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;

}



/* Media Queries */


@media screen and (max-width: 1440px) {
  :root {
    --font-size-base: 23px;
  }
  .sidebar {
    width: 350px;
  }
  .thumbnail {
    width: 260px;
    height: 260px;
  }
}

@media screen and (max-width: 1024px) {
  :root {
    --font-size-base: 22px;
  }
  .sidebar {
    width: 300px;
  }
  .thumbnails {
    background-size: 150px 150px;
    background-position: 149px 149px;
  }
  .thumbnail {
    width: 240px;
    height: 240px;
  }
}

@media screen and (max-width: 780px) {
  :root {
    --font-size-base: 20px;
  }
  .main-container {
    flex-direction: column;
    width: 100%;
  }
  .sidebar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    padding-top: 50px;
  }
  .sidebar.open {
    transform: translateY(0);
  }
  .sidebar-toggle-btn {
    display: block;
  }
  .content {
    width: 100%;
  }
  .project-description {
    flex-direction: column;
    padding: 0 16px;
    padding-bottom: var(--font-size-base);
  }
  .project-text {
    padding-right: 0;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 450px) {
  :root {
    --font-size-base: 18px;
  }
  .thumbnails {
    background-size: 100px 100px;
    background-position: 99px 99px;
  }
  .thumbnail {
    width: 200px;
    height: 200px;
  }
}


@media screen and (max-width: 375px) {
  .thumbnail {
    width: 150px;
    height: 150px;
  }
}
